import { default as React } from "react"
import { graphql, Link } from "gatsby"
import { CgArrowLongRight } from 'react-icons/cg'
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import Sidebar from "~/components/articles/sidebar"
import ArticleTile from "~/components/resources/articles/tile"

const CategoryPage = ({ data: { category } }) => {
  return (
    <Layout>
      <Seo title={category.name} />
      <section
        style={{
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-36 bg-primary bg-cover bg-center overflow-hidden text-white">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full px-8 mb-6">
              <h1
                className="text-7xl text-white mb-2">{category.name}
              </h1>
            </div>
          </div>
          <div className="flex flex-wrap -mx-8">
            <div className="flex flex-col w-full px-8">
              <ul
                className="flex items-center list-none text-white mb-12">
                <li
                  className="mr-3">
                  <Link
                    to={`/resource-center`}>
                    Resource Center
                  </Link>
                </li>
                <li
                  className="mr-3">
                  <CgArrowLongRight />
                </li>
                <li
                  className="mr-3">
                  <span>{category.name}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-28 py-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full md:w-2/3 flex flex-wrap">
              {!!category.posts.nodes.length && (
                category.posts?.nodes.map(post => (
                  <div
                    key={post.databaseId}
                    className="w-full md:w-1/2 px-8 mb-12">
                    <ArticleTile
                      post={post} />
                  </div>
                ))
              )}
            </div>
            <div className="w-full md:w-1/3 px-8">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($databaseId: Int!){
    category: wpCategory(databaseId: { eq: $databaseId }){
      databaseId
      name
      posts{
        nodes{
          databaseId
          title
          slug
          date(formatString: "dddd, MMM Do, YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100, aspectRatio:1)
                }
              }
            }
          } 
          categories {
            nodes {
              databaseId
              slug
              name
            }
          }
        }
      }
    }
  }
`

export default CategoryPage